import { FC, useCallback, useState } from 'react'
import styles from './ProductBasket.module.scss'
import { HeartIcon, HeartIcon1, MinusIcon, PlusIcon } from 'assets'
import { useResponsive } from 'hooks'
import { useAppDispatch } from 'hooks/useTypedSelector'
import { fetchBasketList, removeBasket, updateBasketQuantity } from 'store/features/Basket/basketSlice'
import { Link } from 'react-router-dom'
import { addWatchList, removeWatchList } from 'store/features/Watchlist/watchListSlice'

const ProductBasket: FC<any> = ({ data }) => {
  const { isMobile } = useResponsive()
  const dispatch = useAppDispatch()
  const [isLiked, setLiked] = useState(false)

  const onLikeClickCallback = useCallback(() => {
    const updatedIsLiked = !isLiked
    setLiked(updatedIsLiked)

    if (updatedIsLiked === true) {
      dispatch(addWatchList(data.product_id))
    } else {
      dispatch(removeWatchList(data.product_id))
    }
  }, [isLiked])

  const renderHeartIcon = isLiked ? (
    <HeartIcon role='button' onClick={onLikeClickCallback} className={styles.wrapper__like} />
  ) : (
    <HeartIcon1 role='button' onClick={onLikeClickCallback} className={styles.wrapper__like} />
  )

  const [showProduct, setShowProduct] = useState(true)

  const deleteProduct = () => {
    dispatch(removeBasket(data.cart_id))
    setShowProduct(false)
  }

  const updateQuantity = async (type: string, optionId: number, quantityOption: any) => {
    let quantity = parseInt(quantityOption)
    if (quantity === 1 && type === 'minus') {
      return
    }
    const cartOptionId = optionId

    if (type === 'plus') {
      quantity = quantity + 1
    } else if (type === 'minus') {
      quantity = quantity - 1
    }

    if (quantity >= 1) {
      try {
        await dispatch(updateBasketQuantity({ cartOptionId, quantity: quantity }))

        dispatch(fetchBasketList())
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <>
      {showProduct && (
        <div className={styles.wrapper}>
          <div style={{ position: 'relative' }} className={styles.wrapper__container}>
            <div className={styles.wrapper__container__image}>
              <img width='143px' height='158px' src={data.product.thumb_large} />

              <button onClick={deleteProduct} style={{ color: '#FFA800', fontWeight: 400 }}>
                Видалити
              </button>
            </div>
            <div className={styles.wrapper__container__info}>
              <Link to={`/product/${data.product.id}`} className={styles.wrapper__container__info__title}>
                {data.product.name}
                <br />
                <br />
                <span>{data.option[0].price.priceFormat}</span>
              </Link>
              {data.option.map((option: any, index: number) => (
                <div className='flex flex-col gap-2' key={index}>
                  <div className={styles.wrapper__container__info__main}>
                    <div className={styles.wrapper__container__info__main__item}>
                      <span>Склад:</span>
                      <p>{option?.name}</p>
                    </div>
                    <div className={styles.wrapper__container__info__main__item}>
                      <span>Розмір:</span>
                      <p>{option?.value}</p>
                    </div>
                    {!isMobile && (
                      <>
                        {data.priceType && (
                          <div className={styles.wrapper__container__info__main__item}>
                            <span>Тип ціни:</span>
                            <p>{data.total}</p>
                          </div>
                        )}

                        {/* <div className={styles.wrapper__container__info__main__item}>
                        <span>ціни:</span>
                        <p>{option.price.totalFormat}</p>
                      </div> */}
                      </>
                    )}
                  </div>

                  <div className={styles.wrapper__container__info__quantity}>
                    <div className={styles.wrapper__container__info__quantity__buttons}>
                      <button
                        onClick={() => updateQuantity('minus', option.id, option.quantity)}
                        style={{
                          cursor: option.quantity === 1 ? 'not-allowed ' : 'pointer',
                          opacity: option.quantity === 1 ? '0.6 ' : '1',
                        }}
                        disabled={data.quantity === 1}
                      >
                        <MinusIcon
                          style={{
                            cursor: option.quantity === 1 ? 'not-allowed' : 'pointer',
                          }}
                        />
                      </button>
                      <div className={styles.wrapper__container__info__quantity__buttons__count}>
                        {option.quantity} шт.
                      </div>
                      <button onClick={() => updateQuantity('plus', option.id, option.quantity)}>
                        <PlusIcon />
                      </button>
                    </div>
                    {/* {renderHeartIcon} */}
                  </div>
                </div>
              ))}
              {isMobile && (
                <>
                  {data.priceType && (
                    <div className={styles.wrapper__container__info__main__item}>
                      <span>Всього:</span>
                      <p>{data.total} грн</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className=' absolute right-3 hidden sm:block sm:top-3'>{renderHeartIcon}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProductBasket
